/* Tasks Container */
.tasks-container {
  padding: 0px 20px;
  margin-top: 0;
}

.task-list {
  margin-top: 10px;
}

.task-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.task-left {
  flex-grow: 1;
}

.task-right {
  margin-left: 20px;
}

.task-link {
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
}

.task-link.dimmed {
  color: #6c757d;
}

.task-points {
  margin-top: 5px;
  font-size: 14px;
  color: #6c757d;
}

.join-button, .verify-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.wait-button {
  padding: 5px 10px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 3px;
}

.wait-button:hover {
  cursor: not-allowed;
}

.join-button:hover, .verify-button:hover {
  background-color: #0056b3;
}

.completed-label {
  color: #28a745;
  font-weight: bold;
}

/* Debug Message */
.debug-message {
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.debug-message.success {
  background-color: #28a745;
}

.debug-message.error {
  background-color: #dc3545;
}