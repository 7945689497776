.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin:0;
  width: 100%;
  background-color: #1f2937;  
}

.bg-yellow-500{
  background-color: rgb(151, 151, 18);
  color:rgb(255, 255, 255);
}

.App-main {
  flex: 1; /* Grow to fill available space */
  padding: 0px;
  background-color: #1f2937;
}

.nav-list a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.nav-list a:hover {
  text-decoration: underline;
}

#ad-popup div{
  height:100%;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
} 

.fixed {
  position: fixed;
}

.bottom-0 {
  bottom: 0;
}