.lucky-spin {
  text-align: center;
  margin-top: 50px;
  overflow: hidden; /* Prevent horizontal scrolling */
}

.spin-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.result {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.wheel-container {
  position: relative;
  width: 310px; /* Adjust width as needed */
  height: 310px; /* Adjust height as needed */
  margin: 0 auto; /* Center the wheel */
  overflow: hidden; /* Prevent overflow */
}

.tick {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%) rotate(90deg); /* Center and rotate */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid red; /* Color of the tick */
}